import React, { useEffect } from 'react';
import './Home.css';

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='contentBox'>
      <div className='signupPart'>
        <div className='signupContent'>
          <h2>
            <span className='title'></span>
          </h2>
          <i className='fullShapeSquare fullShapeSquare_1'></i>
          <span className="textarea">
            QUICKEST AND EASIEST WAY TO IDENTIFY A SPORTS CARD
            <i className='mobileTransitionIcon'></i>
          </span>
          <div className="signupBtnPart">
            <button className="downloadAppBtn">
              <a className="btnText" href="https://apps.apple.com/us/app/center-stage-sports-cards/id1577144149" target="_blank" rel="noreferrer">
                <span className="appleIcon"></span>
                Available on the App store
              </a>
            </button>
            {/* <Link to="/signup">
              <button className="signupBtn">
                SIGN UP FOR BETA
              </button>
            </Link> */}
            <span className="btnFrame">
              <span className="topLeftLine"></span>
              <span className="topRightLine"></span>
              <span className="bottomLeftLine"></span>
              <span className="bottomRightLine"></span>
            </span>
          </div>
        </div>
        <div className='imageBox'>
          <span className='signupImage'></span>
          <span className='imageFrame'></span>
        </div>
      </div>
      <i className='fullShapeSquare fullShapeSquare_2'></i>
    </div>
  )
}

export default Home;