import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
// src/pages/index.js를 통해서 한번에 import 할 수 있도록 함
import Home from './Components/Home/Home.js';
// import Signup from './Components/Signup/Signup.js';
import PrivacyNotice from './Components/PrivacyNotice/PrivacyNotice.js';
import Feedback from './Components/Feedback/Feedback.js';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        {/* <Route exact path="/signup" component={Signup} /> */}
        <Route exact path="/privacy" component={PrivacyNotice} />
        <Route exact path="/feedback" component={Feedback} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
