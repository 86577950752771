import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from "react-router-dom";
import axios from 'axios';
import './Feedback.css';

function Feedback() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [feedback, setFeedback] = useState("");

  const validateEmail = (email) => {
    const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return validEmail.test(String(email).toLowerCase());
  };

  const validateName = (name) => {
    const validName = /^[a-z ,.'-]+$/i;
    return validName.test(String(name).toLowerCase());
  };

  const validateFeedback = (feedback) => {
    const validFeedback = /^[a-zA-Z0-9?$@#()'"~!,+\-=_:.&€£*%\s]+$/;
    return validFeedback.test(String(feedback).toLowerCase());
  };

  const handleChange = (e) => {
    const elem = e.target;
    const val = elem.value
      .replace(/(^\s*)|(\s*$)/gi, "") // removes leading and trailing spaces
      .replace(/[ ]{2,}/gi, " ");     // replaces multiple spaces with one space

    if(elem.name === 'email') {
      setEmail(val);
    } else if(elem.name === 'firstName') {
      setFirstName(val);
    } else if(elem.name === 'lastName') {
      setLastName(val);
    } else {
      setFeedback(elem.value);
    }
  }
  
  const goToMainPage = () => {
    history.push('/')
  }

  const submitForm = (e) => {
    e.preventDefault();
    const validEmail = validateEmail(email);
    const validFirstName = validateName(firstName);
    const validLastName = validateName(lastName);
    const validFeedback = validateFeedback(feedback);
    const data = JSON.stringify({email, firstName, lastName, feedback});

    document.getElementById('emailInput').style.outline = 'none';
    document.getElementById('lastNameInput').style.outline = 'none';
    document.getElementById('firstNameInput').style.outline = 'none';
    document.getElementById('feedbackInput').style.outline = 'none';
    
    if(validEmail && validFirstName && validLastName && validFeedback) {
      axios.post('https://gweyy5y7rj.execute-api.us-west-2.amazonaws.com/dev/SendingFeedbackEmail', data)
        .then(() => console.log('Successfully submit.'))
        .catch((err) => {
          console.log('An error has occurred:', err);
        });
      
    }else {
      if(!validEmail) {
        document.getElementById('emailInput').style.outline = '1px solid salmon';
      }
      if(!validFirstName) {
        document.getElementById('firstNameInput').style.outline = '1px solid salmon';
      }
      if(!validLastName) {
        document.getElementById('lastNameInput').style.outline = '1px solid salmon';
      }
      if(!validFeedback) {
        document.getElementById('feedbackInput').style.outline = '1px solid salmon';
      }
    }
    
    goToMainPage();
  }

  return (
    <div className='contentBox feedbackBox'>
      <Link to='/'>
        <i className='feedbackCloseBtn'> CLOSE </i>
      </Link>
      <div className='feedbackContent'>
        <h2>YOUR FEEDBACK</h2>
        <span>
          We would love to hear your thoughts or feedback on how we can improve your experience!
        </span>
        <form onSubmit={submitForm}>
          <h3>REQUIRED FIELDS</h3>
          <input id='emailInput' name='email' type='text' value={email} onChange={handleChange} placeholder='EMAIL' required/>
          <div className='name'>
            <input id='firstNameInput' name='firstName' type='text' value={firstName} onChange={handleChange} placeholder='FIRST NAME' required/>
            <input id='lastNameInput' name='lastName' type='text' value={lastName} onChange={handleChange} placeholder='LAST NAME' required/>
          </div>
          <h3>YOUR FEEEDBACK <span>(Max 250 characters)</span></h3>
          <textarea id='feedbackInput' value={feedback} onChange={handleChange} required maxlength='250'/>
          <div className='feedbackButtonContainer'>
            <input type='submit' className='feedbackButton' value='SUBMIT'/>
          </div>
        </form>
      </div>
    </div>
  )
}

export default withRouter(Feedback);